define("discourse/plugins/discourse-whos-online/discourse/api-initializers/intitialize-whos-online-indicators", ["exports", "discourse/helpers/body-class", "discourse/lib/api", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _bodyClass, _api, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "whos-online";
  var _default = _exports.default = (0, _api.apiInitializer)(api => {
    const siteSettings = api.container.lookup("service:site-settings");
    const whosOnlineService = api.container.lookup("service:whos-online");
    const indicatorType = siteSettings.whos_online_avatar_indicator;
    if (indicatorType === "none") {
      return;
    }
    if (!(siteSettings.whos_online_display_public || api.getCurrentUser()?.trust_level >= siteSettings.whos_online_display_min_trust_level)) {
      return;
    }
    document.documentElement.classList.add(`whos-online-${indicatorType}`);
    const userOnline = id => whosOnlineService.isUserOnline(id);
    api.modifyClass("component:user-card-contents", Superclass => class extends Superclass {
      get classNames() {
        const extraClasses = [];
        if (this.user && userOnline(this.user.id)) {
          extraClasses.push("user-online");
        }
        return [...super.classNames, ...extraClasses];
      }
    });
    api.renderInOutlet("above-user-profile", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if (userOnline @outletArgs.model.id)}}
          {{bodyClass "user-page-online"}}
        {{/if}}
      
    */
    {
      "id": "XMK4ys6H",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,1,[\"model\",\"id\"]]],null],[[[1,\"      \"],[1,[28,[32,1],[\"user-page-online\"],null]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-whos-online/discourse/api-initializers/intitialize-whos-online-indicators.js",
      "scope": () => [userOnline, _bodyClass.default],
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)));
    if (siteSettings.whos_online_avatar_indicator_topic_lists) {
      const addLastPosterOnlineClassNameTransformer = _ref => {
        let {
          value: additionalClasses,
          context: {
            topic
          }
        } = _ref;
        if (topic) {
          const whosOnline = api.container.lookup("service:whos-online");
          const {
            lastPoster,
            lastPosterUser
          } = topic;
          if (whosOnline.isUserOnline(lastPoster?.id || lastPosterUser?.id)) {
            additionalClasses.push("last-poster-online");
          }
        }
        return additionalClasses;
      };
      api.registerValueTransformer("latest-topic-list-item-class", addLastPosterOnlineClassNameTransformer);
      api.registerValueTransformer("topic-list-item-class", addLastPosterOnlineClassNameTransformer);
    }
    api.modifyClass("component:scrolling-post-stream", {
      pluginId: PLUGIN_ID,
      didInsertElement() {
        this._super();
        this.appEvents.on("whosonline:changed", this, this._whosOnlineCallback);
      },
      willDestroyElement() {
        this.appEvents.off("whosonline:changed", this, this._whosOnlineCallback);
      },
      _whosOnlineCallback(changedUserIds) {
        changedUserIds.forEach(id => {
          let postIds = this.attrs.posts.value.filter(_ref2 => {
            let {
              user_id
            } = _ref2;
            return user_id === id;
          }).map(post => post.id);
          postIds.forEach(postId => {
            this.dirtyKeys.keyDirty(`post-${postId}`);
            this.dirtyKeys.keyDirty(`post-${postId}-avatar-${id}`, {
              onRefresh: "updateOnline"
            });
          });
        });
        this.queueRerender();
      }
    });
    api.reopenWidget("post-avatar", {
      buildKey: attrs => `post-${attrs.id}-avatar-${attrs.user_id}`,
      isUserOnline(userId) {
        return this.register.lookup("service:whos-online").isUserOnline(userId);
      },
      defaultState(attrs) {
        return {
          online: this.isUserOnline(attrs.user_id)
        };
      },
      updateOnline() {
        this.state.online = this.isUserOnline(this.attrs.user_id);
      },
      buildClasses(attrs, state) {
        if (state.online) {
          return "user-online";
        }
        return [];
      }
    });
  });
});